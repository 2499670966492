// unDraw images
// https://undraw.co/illustrations
import img_1 from '../../assets/images/201902221.jpg';
import img_2 from '../../assets/images/blessing_service.jpg';
import img_3 from '../../assets/images/202102212.jpg';
import img_4 from '../../assets/images/prime_minister.jpg';
import img_5 from '../../assets/images/202002263.jpg';
import img_6 from '../../assets/images/kahikateaToyama.png';
import img_7 from '../../assets/images/20220224_1.jpg';
import img_8 from '../../assets/images/20220222_1.jpg';

export const homeObjOne = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "",
    description: "At this time the Christchurch City Council had not made a memorial for the earthquake victims. When David Bolam-Smith who comissioned the KAHIKATEA Memorial Sculpture was in Tokyo attending a fund raising dinner for the Christchurch and Tohoku area Japan earthquakes, he had a chat with Mr. Ian Kennedy the then New Zealand Ambassador in Tokyo about the need to have some form of memorial for the Japanese Students. As a result, it was suggested by them to make a sculpture for the Japanese, so when the parents come to Christchurch in Feburary 2012, they have a focal point to remember their lost ones. Funds for the KAHIKATEA Memorial Sculpture were raised in Japan by the Japan Asia-Pacific Ladies Friendship Society and Japanese companies. The sculpture commissioned by David Bolam-Smith, artist Annabel Menzies-Joyce and lighting designer Kevin Cawley, it was unveiled in Christchurch on the first anniversary of the February 22nd 2011 earthquake at an event attended by approximately 100 family members at Christ Collage Chapple.",
    buttonLabel: "Get started",
    imgStart: true,
    img: img_1,
    alt: "donut",
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjTwo = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "",
    description: "On Wednesday 22nd February 2012, KAHIKATEA Memorial Sculpture Blessing Service was held at Christ's College Chapel. The dedication of the sculpture was made by Mr. Tutehounuku Korako. The Blessing and Service was by the Very Reverend Peter Beck. Songs by Jay'ed New Zealand born entertainer from Japan and the Canterbury Japanese Chior. Invited guests included Mr. Hideo Mitamura Japanese Ambassador in Wellington, Consular office of Japan Christchurch, Christchurch Cathedral, Ngai Tahu Whanui, Mr. Thomas Appleton, Ministry foreign Affairs and Trade NZ.",
    buttonLabel: "Get started",
    imgStart: false,
    img: img_2,
    alt: "dreaming",
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "Gifting The KAHIKATEA Memorial Sculpture in Transitional Cathedral",
    description: "February 2011, Venerable Lynda Patterson Acting Dean Christchurch Cathedral informed David Bolam-Smith, that the Christchurch Cathedral Chapter would be permanently placing The KAHIKATEA Memorial Sculpture in the Transitional Cathedral. At that time, David Bolam-Smith informed the Christchurch Cathedral that I would like to gift the KAHIKATEA Memorial Sculpture to the Cathedral.",
    buttonLabel: "Get started",
    imgStart: true,
    img: img_3,
    alt: "dreaming",
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjFour = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "",
    description: "Former Japanese Prime minister Shinzo Abe visited Christchurch on July the 7th 2014 where he and his wife placed a twig of the Pohutukawa on the KAHIKATEA Memorial Sculpture and prayed for the 28 Japanese citizens who lost their lives in the February earthquake 2011.",
    buttonLabel: "Get started",
    imgStart: true,
    img: img_4,
    alt: "dreaming",
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjFive = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "Second KAHIKATEA Memorial Sculpture Commisions",
    description: "After the parents of the Japanese students who lost lives in the Christchurch earthquake February 2011 prayed in front of the KAHIKATEA Memorial Sculpture in Christchurch they asked if a second sculpture could be commissioned and send to Japan. The twin sculpture was completed in 2012 and shipped to Japan freight free by NYK Line Japan and it is now installed in the Toyama College of Foreign Languages, which lost 12 students in the disaster.",
    buttonLabel: "Get started",
    imgStart: false,
    img: img_5,
    alt: "dreaming",
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjSix = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "",
    description: "Mayor of Christchurch Lianne Dalziel, Matt Nichols, International Relations Manager and David Bolam-Smith attended Maori Blessing for the Toyama KAHIKATEA Memorial Sculpture on Wednesday 26 February 2020. The dedication was performed by Kaharoa Manihera a representative of Ngai Tahu Christchurch.",
    imgStart: true,
    img: img_6,
    alt: "dreaming",
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjSeven = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "",
    description: "This photo was taken at the Christchurch Transitional Cathedral on the 9th Anaversary of the Christchurch earthquake. There was an evening service and everyone was invited to place a twig of pahutakawa on the sculpture and pray for the victims of the Christchurch earthquake. The Cathedral hold this service each year.",
    imgStart: false,
    img: img_7,
    alt: "dreaming",
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjEight = {
    id: "stories",
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "",
    description: "The KAHIKATEA Memorial Sculpture was visited in 2022 by Ambassador Ito Japan (left) and David Bolam-Smith (right). Dean of the Christchurch Transitional Cathedral Lawrence Kimberley said a prayer in front of the KAHIKATEA Memorial Sculpture for the 28 Japanese students and all others who died in the Christchurch Earthquake 2011.",
    imgStart: true,
    img: img_8,
    alt: "dreaming",
    dark: false,
    primary: false,
    darkText: true
}